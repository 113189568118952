<template>
  <div v-if="authUserIsAdmin" class="card">
    <div class="card-body">
      <LoadingMessage v-if="isLoading"></LoadingMessage>

      <ErrorMessage v-if="error" :error="error" class="m-0"></ErrorMessage>

      <div v-if="!isLoading && data">
        <div class="mb-3">
          <span class="h5">Comment history</span>
          <em class="small text-muted"> (in the last 26 weeks)</em>
        </div>
        <StatLineChart :chartData="chartData" :unit="'comments'"></StatLineChart>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'AdminPanelCommentHistory',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    StatLineChart: () => import('@/components/chart/StatLineChart'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
    chartData() {
      const labels = [];
      const values = [];
      const d1 = [];
      const d2 = [];

      this.timeline.forEach((t) => {
        const {
          weekNo,
          items,
          startOfWeek,
          endOfWeek,
        } = t;
        labels.unshift(weekNo);
        values.unshift(items.length);
        d1.unshift(startOfWeek.format('D MMM YYYY'));
        d2.unshift(endOfWeek.subtract(1, 'day').format('D MMM YYYY'));
      });

      return {
        labels,
        datasets: [
          {
            data: values,
            d1,
            d2,
          },
        ],
      };
    },
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet('/admin/stat/comments', { days: this.weeks * 7 });
        this.parseData(res.data);
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    parseData(data) {
      console.log(data[0]);
      data.forEach((item) => {
        const weekNo = dayjs(item.createdAt, 'YYYY-MM-DD HH:mm:ss')
          .hour(0)
          .minute(0)
          .second(0)
          .week();
        const timelineIndex = this.timeline.findIndex((t) => t.weekNo === weekNo);
        if (timelineIndex > -1) {
          this.timeline[timelineIndex].items.push(item);
        }
      });
      this.data = data;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      data: null,
      weeks: 26,
      timeline: null,
    };
  },
  mounted() {
    const timeline = [];
    for (let i = 0; i < this.weeks; i += 1) {
      const startOfWeek = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .weekday(i * -7);
      const endOfWeek = startOfWeek.add(1, 'week');
      const weekNo = startOfWeek.week();
      timeline.push({
        startOfWeek,
        endOfWeek,
        weekNo,
        items: [],
      });
    }
    this.timeline = timeline;
    this.fetch();
  },
};
</script>
